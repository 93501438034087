window._ = require('lodash');
window.Popper = require('popper.js').default;
try {
    window.$ = window.jQuery = require('jquery');
    moment = require('moment');

    require('bootstrap');
    require('owl.carousel');
    require("flatpickr");
    require("flatpickr/dist/l10n/fr.js").default.fr;
    require('select2');
    require('bootstrap-select');
    require('ion-rangeslider');
    require('bootstrap-select');
    require('datatables.net-dt');
    require('jquery-toast-plugin');


    require('infinite-scroll/dist/infinite-scroll.pkgd.min.js');
    var Chart = require('chart.js');

    require('./frontend/index');
} catch (e) { console.log(e) }