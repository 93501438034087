$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    window.onscroll = function () { stickyFunction() };
    var header = $("header.sticky-top");

    function stickyFunction() {
        if (window.pageYOffset > 200) {
            header.addClass("is-sticky");
        } else {
            header.removeClass("is-sticky");
        }
    }

    stickyFunction();

    $("#menu-toggle").click(function (e) {
        e.preventDefault();
        $(".dashboard").toggleClass("toggled");
    });

    // Stop dropdown from closing when selecting checkbox
    $('.b-filters .dropdown-menu').click(function (e) {
        e.stopPropagation();
    });

    $('#show-more').click(function () {
        $('#datalist li:hidden').slice(0, 2).show();
        if ($('#datalist li').length == $('#datalist li:visible').length) {
            $('#show-more').hide();
        }
    });

    // Countdown Timer
    function makeTimer(date_start = "19 May 2020 16:38:00 GMT+01:00") {
        var endTime = new Date(date_start);
        endTime = (Date.parse(endTime) / 1000);
        var now = new Date();
        now = (Date.parse(now) / 1000);
        var timeLeft = endTime - now;
        var days = 0;
        var hours = 0;
        var minutes = 0;
        var seconds = 0;
        if (timeLeft > 0) {
            days = Math.floor(timeLeft / 86400);
            hours = Math.floor((timeLeft - (days * 86400)) / 3600);
            minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
            seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        }
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }

        days == 0 ? $(".course-card.active .days").addClass('finished') : $(".course-card.active .days").removeClass('finished');

        if (days == 0 && hours == 0) {
            $(".course-card.active .days").addClass('finished');
            $(".course-card.active .hours").addClass('finished');
        }
        if (days == 0 && hours == 0 && minutes == 0) {
            $(".course-card.active .days").addClass('finished');
            $(".course-card.active .hours").addClass('finished');
            $(".course-card.active .minutes").addClass('finished');
        }
        if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
            $(".course-card.active .days").addClass('finished');
            $(".course-card.active .hours").addClass('finished');
            $(".course-card.active .minutes").addClass('finished');
            $(".course-card.active .seconds").addClass('finished');
        }

        $(".course-card.active .days").html(days + "<span>Jours</span>");
        $(".course-card.active .hours").html(hours + "<span>Heures</span>");
        $(".course-card.active .minutes").html(minutes + "<span>Min</span>");
        $(".course-card.active .seconds").html(seconds + "<span>Sec</span>");
    }

    $('.maxed-list .show-more').click(function () {
        $('.maxed-list ul li:hidden').show();
        if ($('.maxed-list ul li').length === $('.maxed-list ul li:visible').length) {
            $('.maxed-list .show-more').hide();
            $('.maxed-list ul').addClass('hidden');
        }
    });

    // Rating stars
    $('.rating-modal .stars label').on('click', function () {
        $('.rating-modal  .collapse').collapse();
        if ($('.rating-modal  .collapse').hasClass('show')) {
            $(".rating-modal .stars input:radio").attr('disabled', true);
        }
    });

    var toast = false;
    var ajax_call = function () {
        $.ajax({
            url: "/product",
            method: "post",
        }).done(function(response){
            if (response.code === 200) {
                var origin   = window.location.origin;
                let path = origin+'/checkout';
                if (!toast) {
                    toast = $.toast({
                        text : "Votre panier est prêt, veuillez valider votre  <a href="+path+">commande</a>. ",
                        showHideTransition : 'slide',
                        bgColor : '#0080ce',
                        textColor : '#eee',
                        allowToastClose : true,
                        //hideAfter : 10000,
                        hideAfter : 10000,
                        stack : 1,
                        textAlign : 'left',
                        position : 'bottom-right',
                        icon: 'info',
                        afterHidden: function () {
                            if(
                                typeof  response !== 'undefined' &&
                                response && 
                                response.lastCheckout !== 'undefined' &&
                                response.lastCheckout &&
                                response.lastCheckout.id !== 'undefined' &&
                                response.lastCheckout.id 
                            ){
                                console.log(response,response.lastCheckout,response.lastCheckout.id)
                                $.ajax({
                                    url: "/product/isSeen",
                                    data:{
                                        'id':response.lastCheckout.id
                                    },
                                    method: "POST",
                                })
                            }
                        
                        }
                    })
                }
            }
        });
    };

    var interval = 250 * 60 * 1; // where X is your every X minutes
    var m = $("meta[name=check]");
    if (m.attr("content") === 'true') {
        ajax_call();
        setInterval(ajax_call, interval);
    }
    var m = $("meta[name=check]");
    if (m.attr("content") === 'true') {
        $.ajax({
            url : '/bonus',
            type : 'GET',
            success : function(data){
                if(data.code === 200)
                {
                    $('#amountBonus').text(data.data.bonus_remaining_balance);
                    var date = moment(data.data.expiration_date).format('DD/MM/YYYY');
                    $('#dateBonus').text(date);
                    let datePopup = $("meta[name=popup-date]").attr("content");
                    console.log(datePopup);
                    if(!datePopup)
                    {
                        $('#popupBonus').modal('show');
                    }
                    $('#popupBonus').on('shown.bs.modal', function () {
                        $.ajax({
                            url : '/update/popup',
                            type : 'post',
                            success : function(data){

                            },
                            error : function(data){
                            }
                        });
                    });
                }
            },
            error : function(data){
            }
        });
    }
});
